import React from 'react'
import SEO from '../components/Blocks/SEO'
import NotFound from '../components/Blocks/NotFound'
import Page from '../components/Layout/Page'

function NotFoundPage(props) {
  return (
    <React.Fragment>
      <SEO title="404: Not found" />
      <Page>
        <NotFound>Ops! Sidan du söker kunde tyvärr inte hittas</NotFound>
      </Page>
    </React.Fragment>
  )
}

export default NotFoundPage
