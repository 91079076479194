import React from 'react'
import styles from './Footer.module.css'

function Footer(props) {
  return (
    <footer className={styles.footer}>
      © {new Date().getFullYear()} motorsportfans.se
    </footer>
  )
}

export default Footer
