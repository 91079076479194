import React from 'react'
import { Link } from 'gatsby'
import styles from './Header.module.css'

function Header(props) {
  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <Link className={styles.link} to="/">
          <img
            className={styles.logo}
            src="/motorsportfans-logo.svg"
            alt="Motorsportfans.se logo"
            title="Motorsportfans logotype"
          />
        </Link>
      </div>
    </header>
  )
}

export default Header
